export default function customInputUploadInit() {
  const inputs = document.querySelectorAll('.c-inputfile input[type="file"]');
  Array.prototype.forEach.call(inputs, (input) => {
    const container = input.closest('.c-inputfile');
    const label = container.querySelector('.c-inputfile__name');
    const size = container.querySelector('.c-inputfile__size');
    let fileSize = 0;

    input.addEventListener('change', function (e) {
      let fileName = '';

      if (this.files && this.files.length > 1) {
        fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
      } else {
        fileName = e.target.value.split('\\').pop();
      }

      if (this.files && this.files.length === 1) {
        fileSize = parseFloat(this.files[0].size / 1024).toFixed(2);
        fileName = e.target.value.split('\\').pop();
      }

      if (fileName) {
        label.innerHTML = fileName;
      }

      if (fileSize) {
        size.innerHTML = `${fileSize} Ko`;
      }
    });

    // Firefox bug fix
    input.addEventListener('focus', () => { input.classList.add('has-focus'); });
    input.addEventListener('blur', () => { input.classList.remove('has-focus'); });
  });
}
