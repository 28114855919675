export default function () {
  var $ = jQuery;
  var heroHeight = $(".c-hero").outerHeight();

  $(".c-hero_more").click(function () {
    $("html, body").animate(
      {
        scrollTop: $(".c-who-we-are").offset().top,
      }
    );
  });
}
