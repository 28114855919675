import Swiper from "swiper/swiper-bundle";

export default function () {
  var $ = jQuery;

  var swiper = new Swiper(".team-swiper", {
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      prevEl: $(".team-swiper .swiper-button-prev-custom")[0],
      nextEl: $(".team-swiper .swiper-button-next-custom")[0],
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
    },
  });
}
