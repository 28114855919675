/**
 * Flickity carousels helpers and extends functions.
 *
 * @link   https://lespretentieux.slite.com/app/docs/uzDAkiWpG#4c946514
 * @author Les Prétentieux
 */

export default function () {
	var $ = jQuery;

	/**
	 * Resizes the carousels when document is ready
	 */
	$(document).ready(function () {
		resizeCarousels();
		updateCarouselsActions();
	});

	/**
	 * Resizes the carousels when window is loaded
	 * and updates carousel buttons
	 */
	$(window).on("load", function () {
		resizeCarousels();
		updateCarouselsActions();
	});

	/**
	 * Updates carousel buttons when window resizes
	 */
	$(window).on("resize", function () {
		updateCarouselsActions();
	});

	/**
	 * Disables the carousel's cells pointers events to prevent user from clicking the cell while dragging
	 */
	$(".o-carousel").each(function () {
		$(this).on("dragStart.flickity", () => $(this).find(".o-carousel_cell").css("pointer-events", "none"));
		$(this).on("dragEnd.flickity", () => $(this).find(".o-carousel_cell").css("pointer-events", "all"));
	});

	/**
	 * Selects the carousel's previous cell and stops the autoplay
	 */
	$(".o-carousel_btn.-prev").on("click", function () {
		var $actions = $(this).parent(".o-carousel_actions");
		var carouselID = $actions.data("carousel-for");
		var $carousel = $('.o-carousel[data-carousel-id="' + carouselID + '"]');
		var flkty = Flickity.data($carousel[0]);
		flkty.previous();
		flkty.stopPlayer();
	});

	/**
	 * Selects the carousel's next cell and stops the autoplay
	 */
	$(".o-carousel_btn.-next").on("click", function () {
		var $actions = $(this).parent(".o-carousel_actions");
		var carouselID = $actions.data("carousel-for");
		var $carousel = $('.o-carousel[data-carousel-id="' + carouselID + '"]');
		var flkty = Flickity.data($carousel[0]);
		flkty.next();
		flkty.stopPlayer();
	});

	/**
	 * Resizes the carousels to make sure they are properly positioned
	 */
	function resizeCarousels() {
		console.log("resizeCarousels");
		$(".o-carousel").each(function () {
			var flkty = Flickity.data($(this)[0]);
			flkty.resize();
		});
	}

	/**
	 * Hides or shows the carousel's buttons when the number of slides is smaller than 2
	 * (only works with groupCells enabled)
	 */
	function updateCarouselsActions() {
		console.log("updateCarouselsActions");
		$(".o-carousel").each(function () {
			var carouselID = jQuery(this).data("carousel-id");
			var flkty = Flickity.data($(this)[0]);

			if (flkty.slides.length < 2) {
				jQuery(".o-carousel_actions[data-carousel-for='" + carouselID + "']").hide();
			} else {
				jQuery(".o-carousel_actions[data-carousel-for='" + carouselID + "']").show();
			}
		});
	}
}
