export default function () {
	(function (window, jQuery) {
		"use strict";
		var document = window.document;

		var textField = {
			init: function () {
				jQuery(".c-text-field input").on("input", function () {
					if (jQuery(this).val().length > 0) {
						jQuery(this).closest(".c-text-field").addClass("is-active");
					} else {
						jQuery(this).closest(".c-text-field").removeClass("is-active");
					}
				});
			},
		};

		jQuery(document).ready(function () {
			textField.init();
		});
	})(window, jQuery);
}
