export default () => ({
  isActive: false,
  lastPosition: -1,
  init() {
    requestAnimationFrame(() => this.onScroll());
  },
  onScroll() {
    // Avoid calculations if not needed
    if (this.lastPosition === window.pageYOffset) {
      requestAnimationFrame(() => this.onScroll());
      return;
    }

    this.lastPosition = window.pageYOffset;
    this.isActive = this.lastPosition > 0;

    requestAnimationFrame(() => this.onScroll());
  },
});
