export default function () {
  var mouseX = 0;
  var mouseY = 0;
  var translateX = 0;
  var translateY = 0;

  jQuery(window).mousemove(function (event) {
    mouseX = event.clientX;
    mouseY = event.clientY;
  });

  setInterval(move, 1000 / 60);

  function move() {
    translateX = lerp(translateX, mouseX, 0.1);
    translateY = lerp(translateY, mouseY, 0.1);
    var cursorWidth = jQuery(".c-cursor").outerWidth();
    var cursorHeight = jQuery(".c-cursor").outerHeight();

    jQuery(".c-cursor").css(
      "transform",
      "translate3d(" +
        (translateX - cursorWidth / 2) +
        "px, " +
        (translateY - cursorHeight / 2) +
        "px, 0)"
    );
  }

  function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end;
  }
}
