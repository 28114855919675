export default function () {
	var $ = jQuery;

	$("a[href$='#modal']").click(function () {
		$("#modal").toggleClass("js-active");
		$("#modal").toggle().css({ display: "block" });
	});
	$(".c-modal-form__btn-close").click(function () {
		$("#modal").css({ display: "none" });
	});
}
