import Swiper from "swiper/swiper-bundle";

export default function swiperTestimonialsInit() {
  jQuery(".c-swiper-testimonials-content__read-more").click(function (e) {
    e.preventDefault();
    var parent = jQuery(this).closest(".c-swiper-testimonials-content");
    jQuery(this).hide();
    parent.find(".c-swiper-testimonials-content__body-more").show();
    parent.find(".c-swiper-testimonials-content__read-less").show();
  });

  jQuery(".c-swiper-testimonials-content__read-less").click(function (e) {
    e.preventDefault();
    var parent = jQuery(this).closest(".c-swiper-testimonials-content");
    jQuery(this).hide();
    parent.find(".c-swiper-testimonials-content__body-more").hide();
    parent.find(".c-swiper-testimonials-content__read-more").show();
  });

  const $sliders = document.querySelectorAll(".swiper-testimonials");
  const $metas = document.querySelectorAll(
    ".c-swiper-testimonials-actions__meta-item"
  );
  const instancesSwiper = [];

  function updateMeta(activeIndex) {
    $metas.forEach((el, index) => {
      el.style.display = "none";

      if (index === activeIndex) {
        el.style.display = "flex";
      }
    });
  }

  $sliders.forEach(($element, index) => {
    const swiperID = `swiper-testimonials-${index}`;
    const $section = $element.closest('[data-slider="testimonials"]');
    $element.setAttribute("id", swiperID);

    const swiper = new Swiper($element, {
      spaceBetween: 40,
      speed: 500,
      slidesPerView: "auto",
      navigation: {
        prevEl: $section.querySelector(".swiper-button-prev-custom"),
        nextEl: $section.querySelector(".swiper-button-next-custom"),
      },
      pagination: {
        el: ".swiper-pagination-custom",
        type: "custom",
        renderCustom(pSwiper, pCurrent, pTotal) {
          const totalDisplay = pTotal > 10 ? pTotal : `0${pTotal}`;
          const currentDisplay = pTotal > 10 ? pCurrent : `0${pCurrent}`;

          return `<span class="item-${pCurrent}">${currentDisplay}</span><span class="swiper-pagination-custom-sep">—</span><span class="total-${pTotal}">${totalDisplay}</span>`;
        },
      },
      on: {
        init(swiper) {
          updateMeta(swiper.activeIndex);
        },
        slideChange(swiper) {
          updateMeta(swiper.activeIndex);
        },
      },
    });

    instancesSwiper.push(swiper);
  });
}
