export default (id) => ({
	isOpen: false,
	id,
	init() {
		document.addEventListener(
			"modal:open",
			(e) => {
				if (this.id === e.detail) {
					this.isOpen = true;
					this.$nextTick(() => {
						document.querySelector("html").classList.add("no-scroll");
					});
				}
			},
			false
		);
	},
	close() {
		document.querySelector("html").classList.remove("no-scroll");
		this.isOpen = false;
	},
	isVisible() {
		return this.isOpen;
	},
});
