import Swiper from "swiper/swiper-bundle";

export default function swiperServicesInit() {
  const sliderNavSlides = document.querySelectorAll(
    ".c-swiper-services-nav .swiper-slide"
  );
  const sliderNavBtns = document.querySelectorAll(
    ".c-swiper-services-nav .c-swiper-services-nav__link"
  );

  const swiperNav = new Swiper(".c-swiper-services-nav", {
    spaceBetween: 40,
    slidesPerView: "auto",
    freeMode: true,
    breakpoints: {
      // when window width is >= 1001px
      1001: {
        allowTouchMove: false,
      },
    },
  });

  const swiperContent = new Swiper(".c-swiper-services-content", {
    spaceBetween: 40,
    navigation: {
      prevEl: jQuery(
        ".c-swiper-services-content .swiper-button-prev-custom"
      )[0],
      nextEl: jQuery(
        ".c-swiper-services-content .swiper-button-next-custom"
      )[0],
    },
    on: {
      slideChange(swiper) {
        sliderNavSlides.forEach((slide) => {
          slide.classList.remove("is-active");
        });
        sliderNavSlides[swiper.activeIndex].classList.add("is-active");
      },
    },
  });

  sliderNavBtns.forEach((el) => {
    el.addEventListener("click", (e) => {
      e.preventDefault();

      const slideCurrent = el.closest(".swiper-slide");
      const slideIndex = parseInt(el.getAttribute("data-index"), 10);

      swiperContent.slideTo(slideIndex, 300, true);

      sliderNavSlides.forEach((slide) => {
        slide.classList.remove("is-active");
      });
      slideCurrent.classList.add("is-active");
    });
  });
}
