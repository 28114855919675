export default function () {
  var $ = jQuery;

  var speed = 1.75;
  var scrollSpeed = 0;

  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;

  requestAnimationFrame(animate);

  window.addEventListener("wheel", (e) => {
    scrollSpeed = Math.abs(e.deltaY * 0.06);
  });

  $(".o-marquee").each(function () {
    var $marquee = $(this);
    init($marquee);
  });

  function init($marquee) {
    var direction = $marquee.data("direction");
    var $wrapper = $marquee.find(".o-marquee_wrapper");
    var $content = $marquee.find(".o-marquee_content");
    var marqueeWidth = $marquee.outerWidth();
    var wrapperWidth = $wrapper.outerWidth();

    var diff = marqueeWidth / wrapperWidth;
    var duplications = Math.ceil(diff + 1);

    for (var x = 0; x < duplications; x++) {
      $wrapper.append($content.clone());
    }

    if (direction == "reverse") {
      $wrapper.css("transform", "translate3d(" + -wrapperWidth + "px, 0, 0)");
    }
  }

  function animate() {
    scrollSpeed = Math.round((scrollSpeed + Number.EPSILON) * 100) / 100;
    scrollSpeed = lerp(scrollSpeed, 0, 0.04);

    $(".o-marquee").each(function () {
      var $marquee = $(this);
      var direction = $(this).data("direction");
      var scroll = $(this).data("scroll") ? $(this).data("scroll") : false;
      var speedOffset = parseInt(
        $(this).data("speed") ? $(this).data("speed") : 0
      );
      var $wrapper = $marquee.find(".o-marquee_wrapper");
      var $content = $marquee.find(".o-marquee_content");
      var transformX = parseInt(getTransform($wrapper));
      if (direction == "reverse") {
        if (transformX > 0) {
          transformX = -$content.outerWidth();
        }
        if (scroll) {
          transformX = transformX + (speed + speedOffset + scrollSpeed);
        } else {
          transformX = transformX + (speed + speedOffset);
        }
      } else {
        if (-transformX > $content.outerWidth()) {
          transformX = 0;
        }
        if (scroll) {
          transformX = transformX - (speed + speedOffset + scrollSpeed);
        } else {
          transformX = transformX - (speed + speedOffset);
        }
      }

      $wrapper.css("transform", "translate3d(" + transformX + "px, 0, 0)");
    });
    requestAnimationFrame(animate);
  }

  function getTransform($el) {
    var obj = $el;
    var transformMatrix =
      obj.css("-webkit-transform") ||
      obj.css("-moz-transform") ||
      obj.css("-ms-transform") ||
      obj.css("-o-transform") ||
      obj.css("transform");
    var matrix = transformMatrix.replace(/[^0-9\-.,]/g, "").split(",");
    var x = matrix[12] || matrix[4]; //translate x
    if (!x) {
      x = 0;
    }
    return x;
  }

  function lerp(start, end, amt) {
    return (1 - amt) * start + amt * end;
  }
}
