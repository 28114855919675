import AOS from "aos";

export default function aosInit() {
	AOS.init({
		disable: "mobile",
		startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
		initClassName: "aos-init", // class applied after initialization
		animatedClassName: "aos-animate", // class applied on animation
		useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
		disableMutationObserver: false, // disables automatic mutations' detections (advanced)
		debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
		throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

		// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
		offset: 200, // offset (in px) from the original trigger point
		delay: 0, // values from 0 to 3000, with step 50ms
		duration: 400, // values from 0 to 3000, with step 50ms
		easing: "ease-out", // default easing for AOS animations
		once: true, // whether animation should happen only once - while scrolling down
		mirror: false, // whether elements should animate out while scrolling past them
		anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
	});

	// https://github.com/michalsnik/aos/issues/135
	window.addEventListener("load", () => {
		AOS.refresh();
	});

	document.addEventListener("aos:in:animation-number", ({ detail }) => {
		const el = detail.querySelector("[data-number]");
		const finalNumber = parseFloat(el.getAttribute("data-number"));
		
		// If number if greater than 100, we prevent animation to be triggered
		if (finalNumber >= 100) {
			el.innerHTML = finalNumber;
			
			return;
		}
		
		const durationInterval = (20 / end) * 20 * 1;
		let currentNumber = start;

		el.innerHTML = 0;

		const updateNumber = setInterval(() => {
			if (currentNumber < end) {
				currentNumber += 0.5;
				el.innerHTML = currentNumber;
			} else {
				clearInterval(updateNumber);
			}
		}, durationInterval);
	});
}
