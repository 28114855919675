/**
 * Adds basic functionality to the accordion component.
 *
 * @link   https://lespretentieux.slite.com/app/docs/ZG_fg8pgB2GXyy#c871fbfc
 * @author Les Prétentieux
 */

export default function () {
	var $ = jQuery;

	init();

	/**
	 * Initializes accordions when the window has been loaded.
	 */
	$(window).on("load", function () {
		init();
	});

	/**
	 * Initializes accordions when the window resizes.
	 */
	$(window).resize(function () {
		init();
	});

	/**
	 * Toggles the accordion state when the accordion head is clicked.
	 */
	$(".c-accordion_head").click(function () {
		var $component = $(this).closest(".c-accordion");

		$component.toggleClass("is-expanded");
		$component.attr("aria-expanded", $component.attr("aria-expanded") == "true" ? "false" : "true");
	});

	/**
	 * Sets the accordions body height.
	 */
	function init() {
		$(".c-accordion").each(function () {
			var $component = $(this);
			var $content = $(this).find(".c-accordion_content");

			$component[0].style.setProperty("--height", $content.outerHeight() + "px");
		});
	}
}
