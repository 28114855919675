export default function () {
	var $ = jQuery;

	$(".c-header_link, .c-submenu_link")
		.filter(function () {
			return this.href === location.href;
		})
		.addClass("current-page");

	$(".hamburger").click(function () {
		$("body").toggleClass("menu-mobile-is-active");
	});
}
