// polyfill only stable `core-js` features - ES and web standards:
import "core-js/stable";
import "regenerator-runtime/runtime";

import Alpine from "alpinejs";

import navMobile from "./alpine/navMobile";
import modal from "./alpine/modal";
import menuSticky from "./alpine/menuSticky";

import swiperTestimonialsInit from "./modules/swiperTestimonials";
import swiperServicesInit from "./modules/swiperServices";
import swiperTeam from "./modules/swiperTeam";
import customInputUploadInit from "./modules/customInputUpload";
import aosInit from "./modules/animationOnScroll";
import marquee from "./modules/marquee";
import cursor from "./modules/cursor";
import video from "./modules/video";
import modal_btn from "./modules/modal-btn";
import accordion from "./modules/accordion";
import carousel from "./modules/carousel";
import form from "./modules/form";
import file from "./modules/file";
import menu from "./modules/menu";
import seeMore from "./modules/seeMore";

Alpine.data("navMobile", navMobile);
Alpine.data("modal", modal);
Alpine.data("menuSticky", menuSticky);

window.Alpine = Alpine;
Alpine.start();

document.addEventListener("DOMContentLoaded", () => {
	swiperTestimonialsInit();
	swiperServicesInit();
	customInputUploadInit();
	marquee();
	swiperTeam();
	cursor();
	video();
	modal_btn();
	accordion();
	form();
	file();
	menu();
	seeMore();
});

aosInit();
